import React, { useEffect } from "react";

const SecurityLayer = ({ children }) => {
  useEffect(() => {
    // Disable Right-Click
    const disableRightClick = (event) => {
      event.preventDefault();
      alert("Right-click is disabled on this website.");
    };
    //document.addEventListener("contextmenu", disableRightClick);

    return () => {
      //document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  useEffect(() => {
    // Disable User Selection to Prevent Copying
    const disableSelection = () => {
      const style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML = `
        * {
          -webkit-user-select: none !important;
          -moz-user-select: none !important;
          -ms-user-select: none !important;
          user-select: none !important;
        }
      `;
      document.head.appendChild(style);

      return () => {
        document.head.removeChild(style);
      };
    };

    disableSelection();
  }, []);

  useEffect(() => {
    // Prevent Screen Recording by Adding a Transparent Overlay
    const overlay = document.createElement("div");
    overlay.id = "security-overlay";
    overlay.style.position = "fixed";
    overlay.style.top = 0;
    overlay.style.left = 0;
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    overlay.style.background = "rgba(255, 255, 255, 0.001)"; // Transparent overlay
    overlay.style.zIndex = "9999";
    overlay.style.pointerEvents = "none";
    document.body.appendChild(overlay);

    return () => {
      const existingOverlay = document.getElementById("security-overlay");
      if (existingOverlay) {
        document.body.removeChild(existingOverlay);
      }
    };
  }, []);

  return <>{children}</>;
};

export default SecurityLayer;
