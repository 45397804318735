import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Slider from "react-slick";
function GallerySkelton() {

  const sliderActive5Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
    <section className="gallery-section mbm-150">
      <div className="container-fluid">
      <Slider
        {...sliderActive5Item}
        className="slider-active-5-item wow fadeInUp"
      >
         {[...Array(5)].map((_, index) => (

          <div className="single-gallery-item" key={index}>
            <div className="gallery-img">
            
              <Skeleton animation="wave" variant="rectangular" width={450} height={300}  sx={{
                    background: "linear-gradient(to bottom, #a9a9a9, #d4d4d4)",
                    filter: "blur(10px)"
                  }} />
             
            </div>
          </div>
 ))}
          </Slider>
      </div>
    </section>
    </>
    
  )
}

export default GallerySkelton