import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Slider from "react-slick";

function BlogSkeleton({ itemCount = 5 }) {

  const sliderActive5Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="blog-list-section pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-xl-8">
            <div className="blog-list-wrapper">
              {[...Array(itemCount)].map((_, index) => (
                <div className="single-blog-post-four mb-30 wow fadeInDown" key={index}>
                  <div className="post-thumbnail">
                    <Skeleton animation="wave" variant="rectangular" width="100%" height={400} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogSkeleton;
