import React, { useState, useEffect } from "react";

const PromotionalPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "I want to know about booking for Barot Valley",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 5000); // Show popup after 5 seconds
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(""); // Clear error when typing
  };

  const handleWhatsAppSend = () => {
    const { name, email, phone, message } = formData;

    // Validation: Check if all fields are filled
    if (!name || !email || !phone || !message) {
      setError("All fields are required.");
      return;
    }

    // WhatsApp URL generation
    const phoneNumber = "7018079512"; // Replace with your WhatsApp number
    const fullMessage = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${fullMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {/* Overlay */}
      <div
        className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 1050,
          background: "rgba(0, 0, 0, 0.6)",
          animation: "fade-in 0.5s ease",
        }}
      >
        {/* Popup Container */}
        <div
          className="popup-container bg-white rounded-3 shadow-lg position-relative"
          style={{
            width: "90%",
            maxWidth: "600px",
            padding: "20px",
            animation: "zoom-in 0.5s ease",
          }}
        >
          {/* Close Button */}
          <button
            onClick={closePopup}
            className="btn-close position-absolute top-0 end-0 m-3"
            aria-label="Close"
          ></button>

          {/* Popup Content */}
          <div className="text-center">
            <h2
              className="fw-bold"
              style={{
                background: "linear-gradient(to right, #ff7e5f, #feb47b)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginBottom: "15px",
              }}
            >
              Special Booking Offer!
            </h2>
            <p style={{ color: "#333", fontSize: "16px", marginBottom: "20px" }}>
              Plan your escape to the serene Barot Valley! Book your stay at{" "}
              <strong className="text-dark">barotvalley.com</strong> and enjoy
              exclusive discounts, breathtaking views, and unforgettable
              experiences.
            </p>

            {/* Form Fields */}
            <div className="form-group mb-3 d-flex align-items-center">
              <i className="fas fa-user text-primary me-2"></i>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your name"
                className="form-control"
                style={{ borderRadius: "30px" }}
              />
            </div>
            <div className="form-group mb-3 d-flex align-items-center">
              <i className="fas fa-envelope text-primary me-2"></i>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                className="form-control"
                style={{ borderRadius: "30px" }}
              />
            </div>
            <div className="form-group mb-3 d-flex align-items-center">
              <i className="fas fa-phone text-primary me-2"></i>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter your phone"
                className="form-control"
                style={{ borderRadius: "30px" }}
              />
            </div>
            <div className="form-group mb-3 d-flex align-items-start">
              <i className="fas fa-comments text-primary me-2"></i>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Enter your message"
                className="form-control"
                rows="3"
                style={{ borderRadius: "15px" }}
              ></textarea>
            </div>

            {/* Error Message */}
            {error && (
              <div
                className="text-danger mb-3"
                style={{ fontSize: "14px", fontWeight: "bold" }}
              >
                {error}
              </div>
            )}

            {/* WhatsApp Button */}
            <button
              onClick={handleWhatsAppSend}
              className="btn btn-success btn-lg text-uppercase mb-3"
              style={{
                padding: "12px 30px",
                borderRadius: "30px",
                background: "linear-gradient(to right, #11998e, #38ef7d)",
                border: "none",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Send via WhatsApp
            </button>
            <p className="text-muted mt-3">
              By clicking the button, your details will be sent to our customer
              support via WhatsApp.
            </p>
          </div>
        </div>
      </div>

      {/* Animation Keyframes */}
      <style>
        {`
          @keyframes fade-in {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes zoom-in {
            from {
              transform: scale(0.8);
              opacity: 0;
            }
            to {
              transform: scale(1);
              opacity: 1;
            }
          }

          @media (max-width: 768px) {
            .popup-container {
              font-size: 14px;
              padding: 15px;
            }
          }
        `}
      </style>
    </>
  );
};

export default PromotionalPopup;
