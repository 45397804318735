import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'weather-icons/css/weather-icons.css';
import PageBanner from '../../components/PageBanner';
import { Helmet } from 'react-helmet';

const WeatherComponent = () => {
  const [currentWeather, setCurrentWeather] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [city, setCity] = useState('Barot'); // Default city
  const [inputCity, setInputCity] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const apiKey = process.env.REACT_APP_MAP_API;

  useEffect(() => {
    fetchWeatherData(city);
  }, [city]);

  const fetchWeatherData = async (cityName) => {
    setLoading(true);
    setError(null);

    try {
      const currentWeatherResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${apiKey}&units=metric`
      );

      const forecastResponse = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?q=${cityName}&appid=${apiKey}&units=metric`
      );

      setCurrentWeather(currentWeatherResponse.data);
      setForecastData(forecastResponse.data.list);
    } catch (err) {
      setError('City not found. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const getWeatherIcon = (description) => {
    switch (description) {
      case 'Clear':
        return 'wi wi-day-sunny';
      case 'Clouds':
        return 'wi wi-cloudy';
      case 'Rain':
        return 'wi wi-rain';
      case 'Drizzle':
        return 'wi wi-rain-mix';
      case 'Thunderstorm':
        return 'wi wi-thunderstorm';
      case 'Snow':
        return 'wi wi-snow';
      case 'Mist':
        return 'wi wi-fog';
      default:
        return 'wi wi-na';
    }
  };

  const getRecommendations = (temperature) => {
    if (temperature >= 25) {
      return "It's warm! Enjoy a trek or visit the lush greenery of Barot Valley. Pack light clothes and sunscreen.";
    } else if (temperature >= 15) {
      return "It's pleasantly cool. Perfect weather for sightseeing and exploring Barot's scenic beauty.";
    } else if (temperature >= 5) {
      return "It's chilly. Cozy up near a bonfire or explore the valley wrapped in warm layers.";
    } else {
      return "It's freezing! Enjoy the snow-covered landscapes but make sure to bundle up with heavy winter wear.";
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (inputCity.trim() !== '') {
      setCity(inputCity);
      setInputCity('');
    }
  };

  return (
    <>
      <Helmet>
        <title>Barot Valley Weather Today: Current Temperature and Forecast</title>
        <meta
          name="description"
          content="Stay updated with the latest weather in Barot Valley, including today's temperature, current conditions, and forecasts. Discover Barot Valley's serene charm with accurate weather updates perfect for your travel plans. Check Barot Valley temperature today!"
        />
      </Helmet>
      <PageBanner pageTitle="Weather Details" />

      <section className="weather-section py-5">
        <div className="container">
          {/* Search Bar */}
          <div className="search-bar mb-4 text-center">
            <form onSubmit={handleSearch} className="d-flex justify-content-center">
              <input
                type="text"
                className="form-control w-50 me-2"
                placeholder="Enter city name (e.g., Barot, Delhi)"
                value={inputCity}
                onChange={(e) => setInputCity(e.target.value)}
              />
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </form>
          </div>

          {/* Loading or Error */}
          {loading && <div className="text-center">Loading weather data...</div>}
          {error && <div className="text-center text-danger">{error}</div>}

          {!loading && !error && currentWeather && forecastData && (
            <>
              {/* Heading Section */}
              <div className="card mb-5 shadow-lg rounded-4">
                <div className="card-body text-center">
                  <h2
                    className="fw-bold mb-3"
                    style={{
                      fontSize: '28px',
                      background: 'linear-gradient(to right, #1e3c72, #2a5298)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    Weather in {currentWeather.name}, {currentWeather.sys.country}
                  </h2>
                  <p style={{ fontSize: '16px', color: '#6c757d' }}>
                    Accurate weather details and 4-day forecasts for your travel planning.
                  </p>
                </div>
              </div>

              <div className="row">
                {/* Current Weather */}
                <div className="col-md-6">
                  <div className="card text-center shadow-lg rounded-4 mb-4 current-weather-card">
                    <div className="card-body">
                      <i
                        className={`wi ${getWeatherIcon(currentWeather.weather[0].main)} display-3`}
                        style={{ color: '#ff8c00' }}
                      ></i>
                      <h3 className="card-title mt-3">{currentWeather.weather[0].description}</h3>
                      <h1 className="card-temp mt-2">{currentWeather.main.temp}°C</h1>
                      <p className="card-text">Feels Like: {currentWeather.main.feels_like}°C</p>
                      <p className="card-text">Humidity: {currentWeather.main.humidity}%</p>
                      <p className="card-text">Wind Speed: {currentWeather.wind.speed} m/s</p>
                      <div className="recommendation mt-4">
                        <h5 className="text-primary">Recommendation:</h5>
                        <p>{getRecommendations(currentWeather.main.temp)}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Forecast Section */}
                <div className="col-md-6">
                  <h2 className="text-center mb-4">4-Day Weather Forecast</h2>
                  <div className="row">
                    {forecastData.slice(0, 4).map((forecast, index) => (
                      <div key={index} className="col-md-6 mb-3">
                        <div className="card text-center shadow-sm rounded-3 forecast-card">
                          <div className="card-body">
                            <i
                              className={`wi ${getWeatherIcon(forecast.weather[0].main)} display-4`}
                              style={{ color: '#007bff' }}
                            ></i>
                            <h5 className="mt-3">{forecast.weather[0].description}</h5>
                            <h4 className="card-temp mt-2">{forecast.main.temp}°C</h4>
                            <p className="card-text">
                              <strong>Min:</strong> {forecast.main.temp_min}°C |{' '}
                              <strong>Max:</strong> {forecast.main.temp_max}°C
                            </p>
                            <p className="card-text">
                              <strong>Time:</strong> {new Date(forecast.dt * 1000).toLocaleTimeString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      <style>
        {`
          .current-weather-card {
            background: linear-gradient(to right, #1e3c72, #2a5298);
            color: white;
            padding: 30px;
          }

          .card-temp {
            font-size: 48px;
            font-weight: bold;
          }

          .forecast-card {
            background: #f8f9fa;
            color: #333;
            border: none;
            transition: transform 0.3s ease-in-out;
          }

          .forecast-card:hover {
            transform: translateY(-5px);
          }

          @media (max-width: 768px) {
            .weather-section .card {
              margin-bottom: 20px;
            }
          }
        `}
      </style>
    </>
  );
};

export default WeatherComponent;
