import Slider from "react-slick";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import HomeSliderSketon from "../SketonComponents/HomeSliderSketon";

// Function to conditionally load the image URL based on environment
function getSliderImageUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SLIDER__IMAGE_URL_DEVELOPMENT
    : process.env.REACT_APP_SLIDER_IMAGE_URL_PRODUCTION;
}

// Function to conditionally load the API URL based on environment
function getSliderapiUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_SLIDER_DEVELOPMENT
    : process.env.REACT_APP_API_URL_SLIDER_PRODUCTION;
}

function HomeSlider() {
  const imageUrl = getSliderImageUrl();
  const SliderapiUrl = getSliderapiUrl();

  const [sliderData, setSliderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch slider data
  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(SliderapiUrl, { timeout: 10000 }); // Add a timeout
        setSliderData(response.data);
      } catch (error) {
        console.error("Error fetching slider data:", error);
        setError("Failed to load slider data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSliderData();
  }, []);

  // Arrow Component for Slider
  function Arrow({ className, extraClass, onClick, icon }) {
    return (
      <div className={`${className} ${extraClass}`} onClick={onClick}>
        <i className={icon}></i>
      </div>
    );
  }

  // Slider Configuration
  const home1Slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 800,
    fade: true,
    autoplay: true,
    lazyLoad: "ondemand", // Lazy load images
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <Arrow extraClass={"prev"} icon={"fal fa-arrow-left"} />,
    nextArrow: <Arrow extraClass={"next"} icon={"fal fa-arrow-right"} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  function TypewriterEffect({ text }) {
    const [displayedText, setDisplayedText] = useState("");
    const [index, setIndex] = useState(0);

    useEffect(() => {
      if (index < text.length) {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + text[index]);
          setIndex(index + 1);
        }, 50); // Adjust typing speed here
        return () => clearTimeout(timeout);
      }
    }, [index, text]);

    return <h2>{displayedText}</h2>;
  }

  return (
    <>
      <section className="hero-section">
        {/* Hero Wrapper */}
        <div className="hero-wrapper black-bg">
          {isLoading ? (
            <HomeSliderSketon />
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : (
            <Slider {...home1Slider} className="hero-slider-one">
              {sliderData.map((slide, index) => (
                <div className="single-slider" key={index}>
                  <div className="container-fluid">
                    <div className="row align-items-center">
                      {/* Slide Content */}
                      <div className="col-xl-6">
                        <div className="hero-content text-white">
                          <TypewriterEffect text={slide.heading} />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: slide.description,
                            }}
                          />
                          <Link to="/places" className="main-btn primary-btn">
                            Explore More
                            <i className="fas fa-paper-plane" />
                          </Link>
                        </div>
                      </div>
                      {/* Slide Image */}
                      <div className="col-xl-6">
                        <div
                          className="hero-image"
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "400px",
                            overflow: "hidden",
                            backgroundColor: "#e0e0e0", // Grey background as fallback
                          }}
                        >
                          <img
                            src={`${imageUrl}/${slide.image}`}
                            alt={slide.heading}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              opacity: 0, // Start with hidden image
                              transition: "opacity 0.3s ease-in-out",
                            }}
                            onLoad={(e) => {
                              e.target.style.opacity = 1; // Show image once fully loaded
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </section>
    </>
  );
}

export default HomeSlider;
