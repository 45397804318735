import React, { useState } from "react";
import PageBanner from "../../components/PageBanner";
import { Helmet } from "react-helmet";

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Default to the first accordion being open

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is Barot Valley famous for?",
      answer:
        "Barot Valley is renowned for its untouched natural beauty, trout fishing in the Uhl River, trekking trails, and the Shanan Hydel Project, India's first hydroelectric project.",
    },
    {
      question: "How can I reach Barot Valley from Delhi?",
      answer:
        "Barot Valley is approximately 500 km from Delhi. You can drive via NH44, take a bus to Mandi or Joginder Nagar, or fly to Kangra Airport (Dharamshala) and then take a taxi.",
    },
    {
      question: "Are there good homestays or hotels in Barot Valley?",
      answer:
        "Yes, Barot Valley offers a variety of accommodations, including homestays, budget-friendly guest houses, and luxury cottages. These accommodations provide options for families, couples, and solo travelers.",
    },
    {
      question: "What is the best time to visit Barot Valley?",
      answer:
        "The ideal time to visit Barot Valley is from March to June and September to November, when the weather is pleasant, and the views are breathtaking. Winters are cold but offer snow for adventure seekers.",
    },
    {
      question: "What activities can I do in Barot Valley?",
      answer:
        "You can enjoy activities like trout fishing, trekking, camping, nature photography, bird watching, and exploring nearby attractions like Nargu Wildlife Sanctuary.",
    },
    {
      question: "Is Barot Valley safe for solo travelers and families?",
      answer:
        "Yes, Barot Valley is safe for both solo travelers and families. However, basic precautions like informing someone about your travel plans and checking weather conditions are always recommended.",
    },
    {
      question: "Are there any trekking routes near Barot Valley?",
      answer:
        "Yes, Barot Valley offers trekking routes to destinations like Billing, Bada Bhangal, and Kullu. These trails are ideal for adventure enthusiasts.",
    },
    {
      question: "Can I see snow in Barot Valley?",
      answer:
        "Yes, Barot Valley experiences snowfall during the winter months (December to February), making it a perfect destination for snow lovers.",
    },
    {
      question: "Is trout fishing available year-round in Barot Valley?",
      answer:
        "Trout fishing is generally available except during the monsoon season (July-August) when fishing is restricted. Make sure to obtain a fishing permit before you start.",
    },
    {
      question: "What are the nearby attractions in Barot Valley?",
      answer:
        "Nearby attractions include the Shanan Hydel Project, Uhl River, Nargu Wildlife Sanctuary, and trekking routes to Billing and Bada Bhangal. You can also visit nearby villages to experience the local Himachali culture.",
    },
    {
      question: "How is the mobile network and internet connectivity in Barot Valley?",
      answer:
        "Mobile network connectivity is decent, with major providers like Airtel, Jio, and BSNL offering services. However, internet speeds may be slow in some remote areas.",
    },
    {
      question: "Are there restaurants or cafes in Barot Valley?",
      answer:
        "Yes, Barot Valley has a few local restaurants and dhabas offering delicious Himachali cuisine. Some accommodations also provide dining facilities.",
    },
    {
      question: "What are the transportation options within Barot Valley?",
      answer:
        "You can explore Barot Valley by foot, local taxis, or private vehicles. Walking is the best way to explore the valley's serene beauty.",
    },
    {
      question: "Is Barot Valley suitable for a honeymoon trip?",
      answer:
        "Yes, Barot Valley is perfect for a honeymoon trip. Its tranquil environment, scenic views, and cozy accommodations make it an ideal romantic getaway.",
    },
    {
      question: "What should I pack for a trip to Barot Valley?",
      answer:
        "Pack warm clothes, comfortable trekking shoes, a first-aid kit, power banks, and essential toiletries. Don't forget your camera to capture the stunning views!",
    },
    {
      question: "Are there any adventure activities in Barot Valley?",
      answer:
        "Adventure enthusiasts can enjoy trekking, camping, trout fishing, and paragliding at nearby destinations like Billing.",
    },
    {
      question: "What are the cultural experiences in Barot Valley?",
      answer:
        "You can explore local Himachali culture by visiting nearby villages, interacting with locals, and attending regional festivals if your visit coincides with them.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions - Barot Valley</title>
        <meta
          name="description"
          content="Find answers to all your questions about Barot Valley, including activities, travel tips, weather, safety, and accommodation options."
        />
      </Helmet>
      <PageBanner pageTitle="Frequently Asked Questions" />

      <section className="faq-section py-5 bg-light">
        <div className="container">
         
          <div className="accordion" id="faqAccordion">
            {faqs.map((faq, index) => (
              <div className="accordion-item mb-3 border-0 shadow-sm rounded" key={index}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button
                    className={`accordion-button ${activeIndex === index ? "" : "collapsed"} fw-bold`}
                    type="button"
                    onClick={() => toggleFAQ(index)}
                    aria-expanded={activeIndex === index}
                    aria-controls={`collapse${index}`}
                    style={{
                      background: activeIndex === index ? "linear-gradient(to right, #6a11cb, #2575fc)" : "#f8f9fa",
                      color: activeIndex === index ? "white" : "#2c3e50",
                      transition: "background 0.3s ease, color 0.3s ease",
                    }}
                    data-bs-parent="#faqAccordion"
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse collapse ${activeIndex === index ? "show" : ""}`}
                  aria-labelledby={`heading${index}`}
                >
                  <div className="accordion-body" style={{ fontSize: "16px", lineHeight: "1.8" }}>
                    {faq.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQPage;
