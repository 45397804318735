import React from "react";
import Slider from "react-slick";
import Skeleton from "@mui/material/Skeleton";

function HomeSliderSketon({ slideCount = 1 }) {
  // Arrow Component for Slider
  function Arrow({ className, extraClass, onClick, icon }) {
    return (
      <div className={`${className} ${extraClass}`} onClick={onClick}>
        <i className={icon}></i>
      </div>
    );
  }

  // Slider Configuration
  const home1Slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 800,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <Arrow extraClass={"prev"} icon={"fal fa-arrow-left"} />,
    nextArrow: <Arrow extraClass={"next"} icon={"fal fa-arrow-right"} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="hero-section">
      <div className="hero-wrapper black-bg">
        <Slider {...home1Slider} className="hero-slider-one">
          {/* Dynamically Render Skeleton Slides */}
          {Array.from({ length: slideCount }).map((_, index) => (
            <div className="single-slider" key={index}>
              <div className="container-fluid">
                <div className="row align-items-center">
                  {/* Skeleton Text Content */}
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div className="hero-content text-white">
                      {/* Dynamic Skeleton Heading */}
                      <Skeleton
                        variant="rectangular"
                        width="80%"
                        height="clamp(30px, 4vw, 40px)" // Dynamic height
                        animation="wave"
                        sx={{
                          backgroundColor: "#464646",
                          borderRadius: "8px",
                        }}
                      />
                      {/* Dynamic Skeleton Description */}
                      <Skeleton
                        variant="text"
                        width="70%"
                        height="clamp(20px, 2.5vw, 30px)" // Dynamic height
                        animation="wave"
                        sx={{
                          backgroundColor: "#464646",
                          marginTop: "16px",
                        }}
                      />
                      {/* Dynamic Skeleton Button */}
                      <div className="hero-button mt-3">
                        <Skeleton
                          variant="rectangular"
                          width="clamp(100px, 20vw, 150px)" // Dynamic width
                          height="clamp(30px, 4vw, 40px)" // Dynamic height
                          animation="wave"
                          sx={{
                            backgroundColor: "#464646",
                            borderRadius: "8px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Skeleton Image Content */}
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div className="hero-image">
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="clamp(250px, 50vw, 400px)" // Dynamic height
                        animation="wave"
                        sx={{
                          backgroundColor: "#464646",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default HomeSliderSketon;
