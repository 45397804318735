import React, { useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, useNavigate, Outlet, Navigate } from 'react-router-dom';
import HomeLayout from '../layouts/HomeLayout/HomeLayout';
import OtherPagesLayout from '../layouts/PagesLayout/OtherPagesLayout';

import HomePage from '../pages/HomePage/HomePage';
import BlogsPage from '../pages/BlogsPage/BlogsPage.';
import GallerPage from '../pages/GalleryPage/GallerPage';
import AccommodationPage from '../pages/AccommodationPage/AccommodationPage';
import AccommodationDetails from '../pages/AccommodationDetails/AccommodationDetails';

import About from '../pages/About/About';

import ContactPage from '../pages/ContactPage/ContactPage';
import BlogsPageDetails from '../pages/BlogsPage/BlogsPageDetails';
import PlacesPage from '../pages/PlacesPage/PlacesPage';

import BlogsPageDetailsByCategory from '../pages/BlogsPage/BlogsPageDetailsByCategory';

import PlacesDetails from '../pages/PlacesPage/PlacesDetails';

import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import Weather from '../components/Weather/Weather';
import GlobalSecurity from "./GlobalSecurity";
import FAQPage from '../pages/Faq/Faq';
// Create a router instance

// Function to validate routes and query strings
const isValidPathname = (pathname) => {
  const validRoutes = [
    '/home',
    '/blog',
    '/places',
    '/gallery',
    '/accommodation',
    '/contact',
    '/about',
    '/privacy',
    '/weather',
    '/faq'
  ];

  // Check if the pathname starts with any valid route
  return validRoutes.some((route) => pathname.startsWith(route));
};

// Component to validate and redirect globally
const GlobalURLValidator = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;

    // If the current path is not valid, redirect to /home
    if (!isValidPathname(currentPath)) {
      navigate('/home', { replace: true });
    }
  }, [navigate]);

  return children;
};

// Main Router Configuration
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
       <GlobalSecurity>
          <Outlet />
       </GlobalSecurity>
       
      }
    >
      {/* Home Layout Routes */}
      <Route path="/" element={<HomeLayout />} key="home-layout">
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="home" element={<HomePage />} />
      </Route>

      {/* Other Pages Layout Routes */}
      <Route path="*" element={<OtherPagesLayout />} key="other-pages-layout">
        <Route path="blog" element={<BlogsPage />} />
        <Route path="places" element={<PlacesPage />} />
        <Route path="places/:slug" element={<PlacesDetails />} />
        <Route path="gallery" element={<GallerPage />} />
        <Route path="accommodation" element={<AccommodationPage />} />
        <Route path="accommodation/:slug" element={<AccommodationDetails />} />
        <Route path="blog/:slug" element={<BlogsPageDetails />} />
        <Route path="blog/category/:category" element={<BlogsPageDetailsByCategory />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="about" element={<About />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="weather" element={<Weather />} />
        <Route path="faq" element={<FAQPage/>}/>
      </Route>

      {/* Global Wildcard Route */}
      <Route path="*" element={<Navigate to="/home" replace />} key="global-redirect" />
    </Route>
  )
);