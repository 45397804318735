import Slider from "react-slick";
import React, { useState, useEffect, useRef, Suspense } from "react";
import axios from "axios";
import TestimonialSketon from "../TestimonialSketon/TestimonialSketon";

// Function to conditionally load the image URL based on environment
function getTestimonialImageUrl() {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_TESTIMONIALS_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_TESTIMONIALS_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the API URL based on environment
function getTestimonialapiUrl() {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_API_URL_TESTIMONIALS_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_TESTIMONIALS_PRODUCTION; // Production URL
  }
}

function Testimonials() {
  const imageUrl = getTestimonialImageUrl();
  const TestimonialApiUrl = getTestimonialapiUrl();
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const testimonialRef = useRef(null); // Reference for testimonial container

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(TestimonialApiUrl);
        setTestimonials(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  useEffect(() => {
    const adjustHeight = () => {
      if (testimonials.length > 0 && window.innerWidth > 768) {
        const testimonialItems = testimonialRef.current.querySelectorAll(
          ".gw-testimonial-item-two"
        );
        let maxHeight = 0;

        // Calculate the tallest card
        testimonialItems.forEach((item) => {
          maxHeight = Math.max(maxHeight, item.offsetHeight);
        });

        // Apply the tallest height to all cards
        testimonialItems.forEach((item) => {
          item.style.height = `${maxHeight}px`;
        });
      } else if (window.innerWidth <= 768) {
        // Reset heights for mobile screens
        const testimonialItems = testimonialRef.current.querySelectorAll(
          ".gw-testimonial-item-two"
        );
        testimonialItems.forEach((item) => {
          item.style.height = "auto";
        });
      }
    };

    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => {
      window.removeEventListener("resize", adjustHeight);
    };
  }, [testimonials]);

  const sliderActive3ItemDot = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <i
          key={i}
          className={`fas fa-star${i < rating ? "" : "-half-alt"}`}
          style={{ color: i < rating ? "#FFD700" : "#ddd" }}
        />
      );
    }
    return stars;
  };

  return (
    <>
      {/* Testimonial Section */}
      <section
        className="testimonial-section bg_cover pt-100 pb-100"
        style={{ backgroundImage: "url(assets/images/bg/map-bg.jpg)" }}
      >
        <div className="container" ref={testimonialRef}>
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-title text-center text-white mb-60">
                <h2>Barot Valley Reviews</h2>
              </div>
            </div>
          </div>
          {isLoading ? (
            <TestimonialSketon />
          ) : error ? (
            <div className="error-message">
              Error fetching testimonials: {error}
            </div>
          ) : (
            <Suspense fallback={<div>Loading testimonials...</div>}>
              <Slider
                {...sliderActive3ItemDot}
                className="slider-active-3-item-dot"
              >
                {testimonials.map((testimonial) => (
                  <div
                    className="gw-testimonial-item-two"
                    key={testimonial.id}
                  >
                    <div className="testimonial-inner-content">
                      <div className="quote-rating-box">
                        <div className="icon">
                          <img
                            src="assets/images/testimonial/quote.png"
                            alt="quote icon"
                          />
                        </div>
                        <div className="ratings-box">
                          <h4>Visitor Feedback</h4>
                          <ul className="ratings">
                          {renderStars(testimonial.rating || 0)}
                        </ul>
                        </div>
                      </div>
                      <p>{testimonial.description}</p>
                      <div className="author-thumb-title">
                        <div className="author-thumb">
                          <img
                            src={`${imageUrl}/${testimonial.image}`}
                            alt={testimonial.name}
                          />
                        </div>
                        <div className="author-title">
                          <h3 className="title">{testimonial.name}</h3>
                          <p className="position">{testimonial.profile}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </Suspense>
          )}
        </div>
      </section>
    </>
  );
}

export default Testimonials;
