import Slider from "react-slick";
import AccommodationSkelton from "../AccommodationSketon/AccommodationSkelton";
import React, { useState, useEffect, useRef, memo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// Function to conditionally load the image URL based on environment
function getAccommodationImageUrl() {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the API URL based on environment
function getAccommodationapiUrl() {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_PRODUCTION; // Production URL
  }
}

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  return words.length > wordLimit
    ? words.slice(0, wordLimit).join(" ") + "..."
    : text;
};

// Utility function to strip HTML tags
const stripHtmlTags = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const MemoizedAccommodationCard = memo(({ accommodation, imageUrl }) => {
  return (
    <div className="single-service-item mb-40 gw-accommodation-card" key={accommodation.id}>
      <div className="content">
        <h5 className="title" style={{ marginBottom: "10px" }}>
          <Link to={`/accommodation/${accommodation.id}`}>
            {accommodation.name}
          </Link>
        </h5>
        <p>
      {truncateText(stripHtmlTags(accommodation.description), 25)}
    </p>
        <div className="meta">
          <span className="icon">
            <i className="flaticon-blanket" />
          </span>
          <span className="icon">
            <i className="flaticon-caravan" />
          </span>
          <span className="icon">
            <i className="flaticon-travel" />
          </span>
          <span className="icon">
            <i className="flaticon-fire" />
          </span>
        </div>
        <Link
          to={`/accommodation/${accommodation.slug}`}
          className="icon-btn"
          tabIndex="-1"
        >
          <i className="far fa-arrow-right"></i>
        </Link>
      </div>
      <div className="img-holder">
        <img
          src={`${imageUrl}/${accommodation.image[0]}`}
          alt={accommodation.name}
          style={{
            width: "370px",
            height: "250px",
          }}
        />
      </div>
    </div>
  );
});

function Accommodation() {
  const imageUrl = getAccommodationImageUrl();
  const AccommodationApiUrl = getAccommodationapiUrl();
  const [accommodations, setAccommodations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const accommodationRef = useRef(null); // Reference to the parent container

  useEffect(() => {
    const fetchAccommodations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(AccommodationApiUrl); // Replace with your API endpoint
        setAccommodations(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchAccommodations();
  }, []);

  // Dynamic height adjustment (only for larger screens)
  useEffect(() => {
    const adjustHeight = () => {
      if (window.innerWidth > 768) {
        // Only adjust heights for screens wider than 768px
        const accommodationItems = accommodationRef.current.querySelectorAll(
          ".gw-accommodation-card"
        );
        let maxHeight = 0;

        // Calculate the tallest card
        accommodationItems.forEach((item) => {
          maxHeight = Math.max(maxHeight, item.offsetHeight);
        });

        // Apply the tallest height to all cards
        accommodationItems.forEach((item) => {
          item.style.height = `${maxHeight}px`;
        });
      } else {
        // Reset heights for mobile screens
        const accommodationItems = accommodationRef.current.querySelectorAll(
          ".gw-accommodation-card"
        );
        accommodationItems.forEach((item) => {
          item.style.height = "auto";
        });
      }
    };

    // Adjust height on load and on window resize
    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => {
      window.removeEventListener("resize", adjustHeight);
    };
  }, [accommodations]);

  const sliderActive4Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {/*====== Start About Section ======*/}
      <section className="about-section pt-100">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              {/*=== About Content Box ===*/}
              <div className="about-content-box text-center mb-55 wow fadeInDown">
                <div className="section-title mb-30">
                  <h2>
                  Discover the Best Barot Valley Hotels, Homestays,Resort
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-container" ref={accommodationRef}>
            {isLoading ? (
              <AccommodationSkelton />
            ) : error ? (
              <div className="error-message">
                Error fetching accommodations: {error}
              </div>
            ) : (
              <Slider
                {...sliderActive4Item}
                className="slider-active-4-item wow fadeInUp"
              >
                {accommodations.map((accommodation) => (
                  <MemoizedAccommodationCard
                    key={accommodation.id}
                    accommodation={accommodation}
                    imageUrl={imageUrl}
                  />
                ))}
              </Slider>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Accommodation;
