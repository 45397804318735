import React from "react";
import Testimonials from "../../components/Testimonials/Testimonials";
import PageBanner from "../../components/PageBanner";
import { Helmet } from "react-helmet";
import Accommodation from "../../components/Accommodation/Accommodation";

function About() {
  const backgroundImage = process.env.REACT_APP_ABOUT_US_BG_PRODUCTION;

  return (
    <>
      <Helmet>
        <title>
          Barot Valley Himachal: The Story of Its Existence, Attractions, and Why You Should Visit
        </title>
        <meta
          name="description"
          content="Uncover the history and charm of Barot Valley in Himachal, Mandi. Learn how this hidden gem came into existence, why it attracts visitors, and explore iconic spots like Barot Dam. A must-read for nature and history enthusiasts."
        />
      </Helmet>

      {/* Page Banner */}
      <PageBanner pageTitle={"About Barot Valley"} />

      {/* About Section */}
      <section className="about-section pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card shadow-lg border-0 rounded-4">
                <div className="card-body p-4">
                  <h2
                    className="fw-bold text-center mb-4"
                    style={{
                      background: "linear-gradient(to right, #11998e, #38ef7d)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontSize: "28px",
                    }}
                  >
                    Barot Valley Himachal: The Story of Its Existence,
                    Attractions, and Why You Should Visit
                  </h2>
                  <p>
                    Discover Barot Valley: An Unexplored Paradise in the Heart
                    of the Himalayas. Nestled amidst the serene landscapes of
                    Himachal Pradesh, Barot Valley is a pristine and picturesque
                    destination that has rapidly become a favorite among
                    travelers seeking tranquility and natural beauty.
                  </p>

                  <div className="row mt-4">
                    <div className="col-lg-6">
                      <img
                        src="/assets/images/gallery/barot-valley-image.webp"
                        className="rounded mb-40 img-fluid shadow"
                        alt="Barot Valley Image 1"
                      />
                    </div>
                    <div className="col-lg-6">
                      <img
                        src="/assets/images/gallery/barot-valley-images.webp"
                        className="rounded mb-40 img-fluid shadow"
                        alt="Barot Valley Image 2"
                      />
                    </div>
                  </div>

                  <h3 className="mt-4">What is Barot Valley?</h3>
                  <p>
                    Barot Valley is a stunning slice of paradise located in the
                    Mandi district of Himachal Pradesh, approximately 2,000
                    meters above sea level. Surrounded by lush green meadows,
                    dense forests, and the flowing Uhl River, this valley offers
                    an idyllic escape from the chaos of city life.
                  </p>

                  <h3 className="mt-4">How Did Barot Valley Come Into Existence?</h3>
                  <p>
                    Barot's history is as fascinating as its beauty. Originally,
                    Barot was not designed to be a tourist destination. The
                    village came into prominence in the early 1920s during the
                    construction of the Shanan Hydel Project.
                  </p>

                  <h3 className="mt-4">How Did Barot Valley Start Attracting Visitors?</h3>
                  <p>
                    While Barot Valley's initial claim to fame was its
                    contribution to India's hydroelectric power generation, it
                    wasn’t long before its natural beauty became the real star.
                  </p>

                  <h3 className="mt-4">Why Barot Valley is Special Today</h3>
                  <p>
                    Today, Barot Valley stands out as a unique destination that
                    combines the beauty of untouched nature with the simplicity
                    of rural life. Whether you're an adventure lover, a
                    photographer, or someone looking to unwind amidst the
                    mountains, Barot Valley has something to offer.
                  </p>

                  <ul className="features-list mb-4 mt-4">
                    <li>
                      <i className="fas fa-check-circle text-success me-2"></i>
                      Natural Beauty
                    </li>
                    <li>
                      <i className="fas fa-check-circle text-success me-2"></i>
                      Adventure Activities
                    </li>
                    <li>
                      <i className="fas fa-check-circle text-success me-2"></i>
                      Cultural Connection
                    </li>
                    <li>
                      <i className="fas fa-check-circle text-success me-2"></i>
                      Offbeat Escape
                    </li>
                  </ul>

                  <h3 className="mt-4">Why Visit Barot Valley</h3>
                  <p>
                    <strong>Trek through Unexplored Trails:</strong> Barot
                    serves as a starting point for numerous treks, including the
                    trail to Bada Bhangal, a remote tribal village.
                  </p>
                  <p>
                    <strong>Experience Nature’s Bounty:</strong> Marvel at the
                    sight of snow-capped peaks, lush greenery, and flowing
                    rivers.
                  </p>
                  <p>
                    <strong>Connect with History:</strong> Learn about Barot’s
                    historical significance and the fascinating story of the
                    Shanan Hydel Project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section
        className="cta-section text-white text-center pt-100 pb-100"
        style={{
          backgroundImage: "url('/assets/images/bg/cta-bg2.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h2 className="fw-bold mb-4">
            Ready to Travel With Real Adventure and Enjoy Nature?
          </h2>
          <a
            href="https://www.youtube.com/watch?v=iewvgX5vSzc&t=215s"
            className="btn btn-success btn-lg px-5 py-3"
          >
            Watch Video <i className="fas fa-play ms-2"></i>
          </a>
        </div>
      </section>

      {/* Accommodation Section */}
      <Accommodation />

      {/* Testimonials Section */}
      <Testimonials />
    </>
  );
}

export default About;
