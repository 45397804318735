import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AccommodationSkelton from "./AccommodationSkelton";
import PromotionalPopup from "../../components/PromotionalPopup";

function getAccommodationImageUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_DEVELOPMENT
    : process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_PRODUCTION;
}

function getAccommodationApiUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_ACCOMMODATION_DEVELOPMENT
    : process.env.REACT_APP_API_URL_ACCOMMODATION_PRODUCTION;
}

function AccommodationPage() {
  const imageUrl = getAccommodationImageUrl();
  const accommodationApiUrl = getAccommodationApiUrl();

  const [accommodations, setAccommodations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    name: "",
    amenities: "",
    rating: "",
    badgeStatus: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const amenitiesOptions = [
    "Bathroom Facilities",
    "Parking",
    "Room Service",
    "Wi-Fi",
    "Bonfire",
    "Restaurant",
  ];

  const getBadgeStyle = (status) => {
    const styles = {
      position: "absolute",
      top: "10px",
      left: "10px",
      padding: "5px 10px",
      borderRadius: "5px",
      fontSize: "14px",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      gap: "5px",
    };

    switch (status?.toLowerCase()) {
      case "top rated":
        return { ...styles, background: "#f7451e", color: "white" };
      case "popular":
        return { ...styles, background: "#ffa500", color: "white" };
      case "recommended":
        return { ...styles, background: "#007bff", color: "white" };
      case "budget friendly":
        return { ...styles, background: "#6c757d", color: "white" };
      default:
        return { ...styles, background: "#e91e63", color: "white" };
    }
  };

  const getBadgeIcon = (status) => {
    switch (status?.toLowerCase()) {
      case "top rated":
        return <i className="fas fa-star"></i>;
      case "popular":
        return <i className="fas fa-heart"></i>;
      case "recommended":
        return <i className="fas fa-thumbs-up"></i>;
      case "budget friendly":
        return <i className="fas fa-wallet"></i>;
      default:
        return <i className="fas fa-tag"></i>;
    }
  };

  const StarRating = ({ rating }) => {
    const renderStars = () => {
      const stars = [];
      for (let i = 1; i <= 5; i++) {
        if (i <= Math.floor(rating)) {
          stars.push(<i className="fas fa-star" key={i} style={{ color: "#ffc107" }}></i>);
        } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
          stars.push(<i className="fas fa-star-half-alt" key={i} style={{ color: "#ffc107" }}></i>);
        } else {
          stars.push(<i className="far fa-star" key={i} style={{ color: "#ffc107" }}></i>);
        }
      }
      return stars;
    };

    return (
      <div className="ratings-container" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <ul className="ratings" style={{ margin: 0, padding: 0, display: "flex", listStyle: "none" }}>
          {renderStars()}
        </ul>
        <span style={{ fontSize: "14px", color: "#333", fontWeight: "bold" }}>
          {rating} out of 5
        </span>
      </div>
    );
  };

  useEffect(() => {
    fetchAccommodations();
  }, [filters, currentPage]);

  const fetchAccommodations = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(accommodationApiUrl, {
        params: {
          name: filters.name || undefined,
          amenities: filters.amenities || undefined,
          rating: filters.rating || undefined,
          badgeStatus: filters.badgeStatus || undefined,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      const data = response.data.data || response.data;
      setAccommodations(data);
      setTotalPages(response.data.last_page || 1);

      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching accommodations:", err);
      setError("Failed to fetch accommodations.");
      setIsLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setFilters({
      name: "",
      amenities: "",
      rating: "",
      badgeStatus: "",
    });
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Helmet>
        <title>Barot Valley Hotels - Find the Best Stays in Himachal's Hidden Gem</title>
        <meta
          name="description"
          content="Explore top-rated hotels in Barot Valley, nestled in the heart of Himachal Pradesh. Find budget-friendly stays, cozy cottages, and luxurious retreats surrounded by pristine nature. Plan your Barot Valley getaway today and experience unmatched tranquility"
        />
      </Helmet>

      <PromotionalPopup />

      <section className="products-section pt-100 pb-90">
        {/* Heading */}
        <div className="container">
          <h2 className="text-center mb-4" style={{ fontWeight: "bold", fontSize: "28px" }}>
            Barot Valley Hotels - Find the Best Stays in Himachal's Hidden Gem
          </h2>
          <p className="text-center mb-4" style={{ fontSize: "16px", color: "#6c757d" }}>
            Explore a curated list of accommodations in Barot Valley. Choose from top-rated stays, budget-friendly
            options, and luxurious retreats to make your trip memorable.
          </p>

          {/* Filter Section */}
          <div
            className="filter-box"
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <h3 className="mb-4" style={{ fontWeight: "bold" }}>
              Filter Accommodations
            </h3>
            <div className="row g-3 mb-4">
              <div className="col-md-6">
              <select
  className="form-select"
  name="name"
  value={filters.name}
  onChange={handleFilterChange}
>
  <option value="">All Accommodations</option>
  {accommodations.map((accommodation, index) => (
    <option key={index} value={accommodation.name}>
      {accommodation.name}
    </option>
  ))}
</select>

              </div>
              <div className="col-md-6">
  <select
    className="form-select"
    name="amenities"
    value={filters.amenities}
    onChange={(e) =>
      setFilters({
        ...filters,
        amenities: e.target.value, // Ensure this sends a single amenity or a comma-separated list
      })
    }
  >
    <option value="">All Amenities</option>
    <option value="bathroom facilities">Bathroom Facilities</option>
    <option value="parking">Parking</option>
    <option value="room service">Room Service</option>
    <option value="Wi-Fi">Wi-Fi</option>
    <option value="bonfire">Bonfire</option>
    <option value="Restaurant">Restaurant</option>
  </select>
</div>

              <div className="col-md-6">
                <select className="form-select" name="rating" value={filters.rating} onChange={handleFilterChange}>
                  <option value="">Rating (Any)</option>
                  <option value="1">1 Star</option>
                  <option value="2">2 Stars</option>
                  <option value="3">3 Stars</option>
                  <option value="4">4 Stars</option>
                  <option value="5">5 Stars</option>
                </select>
              </div>
              <div className="col-md-6">
                <select
                  className="form-select"
                  name="badgeStatus"
                  value={filters.badgeStatus}
                  onChange={handleFilterChange}
                >
                  <option value="">Badge Status (Any)</option>
                  <option value="top rated">Top Rated</option>
                  <option value="popular">Popular</option>
                  <option value="recommended">Recommended</option>
                  <option value="budget friendly">Budget Friendly</option>
                </select>
              </div>
            </div>
            <div className="text-center">
              <button className="btn btn-success me-3" onClick={fetchAccommodations}>
                Apply Filters
              </button>
              <button className="btn btn-secondary" onClick={resetFilters}>
                Reset Filters
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            {isLoading ? (
              <AccommodationSkelton count={10} />
            ) : error ? (
              <div className="text-center">{error}</div>
            ) : accommodations.length > 0 ? (
              accommodations.map((accommodation, index) => (
                <div className="col-xl-4 col-md-6 mb-4" key={index}>
                  <div
  className="card h-100"
  style={{
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  }}
>
  {/* Image Section */}
  <div
    className="img-holder"
    style={{
      position: "relative",
      overflow: "hidden",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    }}
  >
    <Link to={`/accommodation/${accommodation.slug}`}>
      <img
        src={`${imageUrl}/${accommodation.image?.[0] || "default-image.jpg"}`}
        alt={accommodation.name || "Accommodation"}
        style={{
          height: "300px", // Consistent image height
          width: "100%",
          objectFit: "cover", // Maintain aspect ratio
          transition: "transform 0.3s ease",
        }}
      />
    </Link>
    <div style={getBadgeStyle(accommodation.badge_status)}>
      {getBadgeIcon(accommodation.badge_status)}
      <span>{accommodation.badge_status}</span>
    </div>
  </div>

  {/* Card Body */}
  <div className="card-body">
    <h5 className="card-title">
      <Link to={`/accommodation/${accommodation.slug}`} style={{ textDecoration: "none", color: "#333" }}>
        {accommodation.name}
      </Link>
    </h5>
    <StarRating rating={Number(accommodation.rating)} />
   
    <div className="amenities">
      <h6 className="mt-3" style={{ fontSize: "14px", fontWeight: "bold" }}>Amenities:</h6>
      <ul style={{ listStyle: "none", padding: 0 }}>
        {accommodation.amenities.slice(0, 3).map((amenity, idx) => (
          <li key={idx}>
            <i className="fas fa-check-circle text-success me-2"></i>
            {amenity}
          </li>
        ))}
      </ul>
    </div>
    <div className="text-center mt-3">
      <Link
        to={`/accommodation/${accommodation.slug}`}
        className="btn btn-primary btn-sm"
        style={{ borderRadius: "5px" }}
      >
        View Details
      </Link>
    </div>
  </div>
</div>

                </div>
              ))
            ) : (
              <div className="text-center">No accommodations available at the moment.</div>
            )}
          </div>

          <div className="row">
            <div className="col-lg-12">
              <ul className="pagination justify-content-center mt-4 mb-10">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <a className="page-link">Previous</a>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    <a className="page-link">{index + 1}</a>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <a className="page-link">Next</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AccommodationPage;
