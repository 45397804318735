import Slider from "react-slick";
import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PlacesSkelton from "./PlacesSkelton";

// Function to conditionally load the image URL based on environment
function getPlacesImageUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_PLACES_IMAGE_URL_DEVELOPMENT
    : process.env.REACT_APP_PLACES_IMAGE_URL_PRODUCTION;
}

// Function to conditionally load the API URL based on environment
function getPlacesapiUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_PLACES_DEVELOPMENT
    : process.env.REACT_APP_API_URL_PLACES_PRODUCTION;
}

// Memoized component for individual places
const MemoizedPlaceCard = memo(({ place, imageUrl }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Link key={place.id} to={`/places/${place.slug}`}>
      <div className="single-features-item-three">
        <div
          className="img-holder"
          style={{
            position: "relative",
            width: "100%",
            height: "343px", // Fixed height to maintain design consistency
            overflow: "hidden",
            borderRadius: "8px", // Optional: Adds rounded corners
          }}
        >
          {/* Blurred skeleton placeholder while the image is loading */}
          {!isImageLoaded && (
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "linear-gradient(to bottom, #d4d4d4, #f0f0f0)",
                filter: "blur(8px)", // Apply blur effect
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
              }}
            />
          )}

          {/* Original Image */}
          <img
            src={`${imageUrl}/${place.image}`}
            alt={place.name}
            style={{
              width: "100%",
             
              objectFit: "cover",
              display: isImageLoaded ? "block" : "none",
            }}
            onLoad={() => setIsImageLoaded(true)} // Show the image once fully loaded
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = ""; // Optional: Set fallback if needed
            }}
          />
        </div>

        {/* Content Section with Places Name */}
        <div className="content" style={{ textAlign: "center", marginTop: "10px" }}>
          <h6
            style={{
              margin: "5px 0",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            {place.name}
          </h6>
        </div>
      </div>
    </Link>
  );
});

function Places() {
  const imageUrl = getPlacesImageUrl();
  const placesApiUrl = getPlacesapiUrl();
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        setIsLoading(true);

        // Fetch only the latest 4 records
        const response = await axios.get(placesApiUrl, {
          params: {
            per_page: 4, // Limit to 4 records
            order_by: "created_at", // Fetch by creation date (or last updated, if available)
            order: "desc", // Fetch the latest (descending order)
          },
        });

        if (response.data && response.data.data) {
          setPlaces(response.data.data); // Extract places
        } else {
          throw new Error("Unexpected API response format");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlaces();
  }, []); // Fetch once on component mount

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="features-section gray-bg pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title mb-55">
              <h2>Best Places to Visit in Barot Valley</h2>
            </div>
          </div>
        </div>

        {isLoading && <PlacesSkelton/>} {/* Use your existing skeleton here */}
        {error && <div className="error-message">{error}</div>}

        {!isLoading && places.length > 0 && (
          <Slider {...sliderSettings} className="slider-active-5-item wow fadeInUp">
            {places.map((place) => (
              <MemoizedPlaceCard key={place.id} place={place} imageUrl={imageUrl} />
            ))}
          </Slider>
        )}

        {!isLoading && !error && places.length === 0 && (
          <div className="text-center">No places available at the moment.</div>
        )}
      </div>
    </section>
  );
}

export default Places;
