import React from "react";
import Skeleton from "@mui/material/Skeleton"; // Material UI Skeleton component

function AccommodationSkelton({ count = 3 }) {
  // Dynamic skeletons based on the `count` prop
  const skeletonItems = Array.from({ length: count });

  return (
    <>
      {skeletonItems.map((_, index) => (
        <div className="col-xl-4 col-md-6 col-sm-12" key={index}>
          <div
            className="single-product-item mb-50 wow fadeInUp"
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <div className="img-holder">
              <Skeleton
                variant="rectangular"
                width={400}
                height={400}
                sx={{
                  background: "linear-gradient(to bottom, #a9a9a9, #d4d4d4)",
                  filter: "blur(10px)"
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default AccommodationSkelton;
