import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import PageBanner from "../../components/PageBanner";
import BlogSkelton from "../../components/SketonComponents/BlogSkelton";
import { Helmet } from "react-helmet";

// Function to conditionally load the image URL based on environment
function getBlogImageUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BLOGS_IMAGE_URL_DEVELOPMENT
    : process.env.REACT_APP_BLOGS_IMAGE_URL_PRODUCTION;
}

// Function to conditionally load the API URL based on environment
function getBlogApiUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_BLOG_DEVELOPMENT
    : process.env.REACT_APP_API_URL_BLOG_PRODUCTION;
}

function BlogsPageDetailsByCategory() {
  const { category } = useParams(); // Extract category name from URL
  const blogApiCategories = process.env.REACT_APP_BlogApiUrl_categories; // Categories endpoint
  const blogApiPosts = process.env.REACT_APP_BlogApiUrl_all_blogs; // Posts endpoint with _embed

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Step 1: Fetch Category ID by Category Name
  useEffect(() => {
    const fetchCategoryId = async () => {
      try {
        const response = await axios.get(blogApiCategories);
        const categories = response.data;

        const matchedCategory = categories.find(
          (cat) => cat.name.toLowerCase() === category.toLowerCase()
        );

        if (matchedCategory) {
          setCategoryId(matchedCategory.id); // Save the category ID
        } else {
          setError("Category not found");
        }
      } catch (err) {
        setError("Error fetching categories");
      }
    };

    fetchCategoryId();
  }, [category]);

  // Step 2: Fetch Blogs by Category ID
  useEffect(() => {
    if (!categoryId) return;

    const fetchBlogs = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(blogApiPosts, {
          params: { categories: categoryId },
        });

        if (response.data) {
          setBlogs(response.data);
        } else {
          setBlogs([]);
        }
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, [categoryId]);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    } catch (error) {
      return "Invalid Date";
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <BlogSkelton variant="blog-list" itemCount={4} />;
    }

    if (error) {
      return <p>{error}</p>;
    }

    if (blogs.length === 0) {
      return <p>No blogs found for this category.</p>;
    }

    return (
      <>
        <div className="row">
          <div className="col-xl-8">
            <div className="blog-list-wrapper">
              {currentBlogs.map((blog) => (
                <div
                  key={blog.id}
                  className="single-blog-post-four mb-30 wow fadeInDown"
                >
                  <Helmet>
                    <title>{blog.title.rendered} - Barot Valley Blogs</title>
                    <meta
                      name="description"
                      content={`Read about ${blog.title.rendered}, published on ${formatDate(
                        blog.date
                      )}. Stay informed with the latest updates and insights from Barot Valley.`}
                    />
                  </Helmet>
                  <div className="post-thumbnail">
                    <img
                      src={
                        blog._embedded?.["wp:featuredmedia"]?.[0]?.source_url ||
                        "https://via.placeholder.com/300x200"
                      }
                      alt={blog.title.rendered}
                    />
                  </div>
                  <div className="entry-content">
                    <div className="post-meta">
                      <span>
                        <i className="far fa-calendar-alt" />
                        {formatDate(blog.date)}
                      </span>
                    </div>
                    <h3 className="title">
                      <Link to={`/blog/${blog.slug}`}>
                        {blog.title.rendered}
                      </Link>
                    </h3>
                    <h6 className="author">
                      <i className="far fa-user" />
                      Barot Valley
                    </h6>
                    <Link
                      to={`/blog/${blog.slug}`}
                      className="main-btn filled-btn"
                    >
                      Read More <i className="fas fa-paper-plane" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <ul className="gowilds-pagination wow fadeInDown mt-60 mb-30">
              {Array.from({ length: Math.ceil(blogs.length / blogsPerPage) }).map(
                (_, index) => (
                  <li
                    key={index}
                    className={`me-2 ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        paginate(index + 1);
                      }}
                    >
                      {index + 1}
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <PageBanner pageTitle={`Blogs in Category: ${category}`} />
      <section className="blog-list-section pt-100 pb-70">
        <div className="container">{renderContent()}</div>
      </section>
    </>
  );
}

export default BlogsPageDetailsByCategory;
