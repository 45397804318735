import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PageBanner from "../../components/PageBanner";
import GallerySkelton from "../../components/SketonComponents/GallerySkelton";
import { Helmet } from "react-helmet";

function BlogsPage({ paginationStyle }) {
  const blogApiAll = process.env.REACT_APP_BlogApiUrl_all_blogs;
  const BlogApiUrl = `${blogApiAll}`;

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [uniqueCategories, setUniqueCategories] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(BlogApiUrl, {
          params: { page: currentPage, per_page: 5 },
        });

        if (response.data) {
          setBlogs(response.data);
          setTotalPages(parseInt(response.headers["x-wp-totalpages"], 10));

          const categories = [];
          response.data.forEach((blog) => {
            if (blog._embedded?.["wp:term"]?.[0]) {
              blog._embedded["wp:term"][0].forEach((cat) => {
                if (!categories.find((c) => c.id === cat.id)) {
                  categories.push(cat);
                }
              });
            }
          });

          setUniqueCategories(categories);
        } else {
          throw new Error("Unexpected API response format");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, [currentPage]);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <GallerySkelton variant="blog-list" />;
    }

    if (error) {
      return <p>Error loading blogs: {error}</p>;
    }

    return (
      <div className="row">
        <div className="col-xl-8">
          <div className="blog-list-wrapper">
            {blogs.map((blog) => {
              const featuredImage =
                blog._embedded &&
                blog._embedded["wp:featuredmedia"] &&
                blog._embedded["wp:featuredmedia"][0]?.source_url;

              return (
                <div
                  key={blog.id}
                  className="single-blog-post mb-30 wow fadeInUp"
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="post-thumbnail"
                    style={{
                      width: "100%",
                      height: "300px",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <Link to={`/blog/${blog.slug}`}>
                      <img
                        src={featuredImage || "https://via.placeholder.com/600x400"}
                        alt={blog.title.rendered}
                        className="img-fluid"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseOver={(e) => {
                          e.target.style.transform = "scale(1.1)";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.transform = "scale(1)";
                        }}
                      />
                    </Link>
                  </div>
                  <div
                    className="content p-4"
                    style={{ background: "#f9f9f9", minHeight: "200px" }}
                  >
                    <div className="post-meta mb-2">
                      <span>
                        <i className="far fa-calendar-alt me-2" />
                        {formatDate(blog.date)}
                      </span>
                    </div>
                    <h3 className="title">
                      <Link to={`/blog/${blog.slug}`}>{blog.title.rendered}</Link>
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{ __html: blog.excerpt.rendered }}
                      style={{ fontSize: "15px", color: "#555" }}
                    />
                    <Link
                      to={`/blog/${blog.slug}`}
                      className="btn btn-primary mt-3"
                      style={{
                        borderRadius: "30px",
                        padding: "10px 20px",
                        fontSize: "14px",
                      }}
                    >
                      Read More <i className="fas fa-arrow-right ms-2"></i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>

          {totalPages > 1 && (
            <div className="pagination d-flex justify-content-center mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="btn btn-secondary mx-2"
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`btn mx-1 ${
                    currentPage === index + 1 ? "btn-primary" : "btn-outline-primary"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="btn btn-secondary mx-2"
              >
                Next
              </button>
            </div>
          )}
        </div>

        <div className="col-xl-4">
          <div className="sidebar-widget-area">
            <div
              className="sidebar-widget category-widget mb-30 wow fadeInUp"
              style={{
                background: "#f9f9f9",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h4 className="widget-title">Categories</h4>
              <ul>
                {uniqueCategories.length > 0 ? (
                  uniqueCategories.map((category, index) => (
                    <li key={index}>
                      <Link to={`/blog/category/${category.slug}`}>
                        {category.name}
                      </Link>
                    </li>
                  ))
                ) : (
                  <li>No categories found</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <PageBanner pageTitle="Blogs" />
      <Helmet>
        <title>Discover Barot Valley: Hidden Gems, Travel Tips, and Must-Visit Places</title>
        <meta
          name="description"
          content="Explore the beauty of Barot Valley with our curated blogs. Uncover hidden gems, practical travel tips, breathtaking destinations, and everything you need to plan your perfect Barot Valley adventure. Start your journey today!"
        />
      </Helmet>
      <section className="blog-list-section pt-100 pb-70">
        <div className="container">{renderContent()}</div>
      </section>
    </>
  );
}

export default BlogsPage;
