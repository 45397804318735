import React from "react";
import Skeleton from "@mui/material/Skeleton";

function PlacesSkelton({ count = 4 }) {
  // Create an array with the length of `count`
  const skeletonArray = Array.from({ length: count });

  return (
    <div className="row">
      {skeletonArray.map((_, index) => (
        <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-4">
          <div className="single-features-item-three">
            <div className="img-holder">
              <Skeleton
                variant="rectangular"
                width="100%"
                height={350}
                style={{ borderRadius: "10px", backgroundColor: "#e0e0e0" }}
              />
            </div>
            <div className="content">
              <Skeleton
                variant="text"
                width="60%"
                height={30}
                style={{ margin: "10px 0", backgroundColor: "#e0e0e0" }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PlacesSkelton;
