import React, { useState, useEffect } from "react";
import axios from "axios";
import PageBanner from "../../components/PageBanner";
import GallerySkelton from "../../components/SketonComponents/GallerySkelton";
import LazyLoad from "react-lazyload";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Function to conditionally load the image URL based on environment
function getGalleryImageUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_GALLERY_IMAGE_URL_DEVELOPMENT
    : process.env.REACT_APP_GALLERY_IMAGE_URL_PRODUCTION;
}

// Function to conditionally load the API URL based on environment
function getGalleryapiUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_GALLERY_DEVELOPMENT
    : process.env.REACT_APP_API_URL_GALLERY_PRODUCTION;
}

function GalleryPage({ customPaginationFunction }) {
  const imageUrl = getGalleryImageUrl();
  const GalleryApiUrl = getGalleryapiUrl();

  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const itemsPerPage = 9; // Number of items per page

  const fetchGalleries = async (page) => {
    try {
      setIsLoading(true);
      const response = await axios.get(GalleryApiUrl, {
        params: {
          page: page,
          per_page: itemsPerPage, // Assuming API supports pagination
        },
      });
      setGalleries(response.data.data || []); // Assuming API returns data array
      setTotalPages(response.data.total_pages || 1); // Assuming API returns total pages
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGalleries(currentPage); // Fetch galleries for the current page
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); // Update the current page
    }
  };

  const renderGalleries = () => {
    if (isLoading) {
      return <GallerySkelton variant="gallery" />;
    }

    if (error) {
      return <p>Error loading galleries: {error.message}</p>;
    }

    return (
      <>
        <Helmet>
          <title>Barot Valley Images: A Visual Journey</title>
          <meta
            name="description"
            content="Experience the magic of Barot Valley virtually from your devices. Click the link to view Barot Valley images."
          />
        </Helmet>
        <section className="about-gallery-section pt-100 pb-70">
          <div className="container">
            <div className="card bg-light text-dark shadow-lg border-0 rounded-3 p-4">
              <div className="card-body">
                <h3 className="card-title  fw-bold mb-4">
                  About Barot Valley Images
                </h3>
                <p className="card-text" style={{ fontSize: "16px", lineHeight: "1.8" }}>
                  Discover the stunning beauty of Barot Valley through our curated gallery. Each image captures
                  the breathtaking charm of this hidden gem nestled in the Himalayas. From lush green landscapes
                  to mesmerizing rivers and serene mountains, every photo tells a story of nature's untouched
                  splendor. Whether you're planning your next adventure or simply seeking a visual escape, our
                  gallery is your window to the wonders of Barot Valley.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="places-section pb-100">
          <div className="container">
            <div className="row justify-content-center">
              {galleries.map((gallery, index) => (
                <div className="col-xl-4 col-md-6 col-sm-12 places-column" key={index}>
                  <div className="single-place-item mb-60 wow fadeInUp">
                    <div className="place-img">
                      <LazyLoad
                        offset={calculateOffset(index, galleries.length)}
                        placeholder={<GallerySkelton variant="gallery-item" />}
                      >
                        <img
                          src={`${imageUrl}/${gallery.image}`}
                          alt={gallery.alt_text}
                          style={{
                            height: "400px",
                          }}
                        />
                      </LazyLoad>
                    </div>

                    <div className="place-content">
                      <div className="info">
                        <h6 className="title">
                         {gallery.alt_text}
                        </h6>
                        <p>{gallery.info}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Dynamic Pagination */}
            {customPaginationFunction ? (
              customPaginationFunction(currentPage, totalPages, handlePageChange)
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <ul className="gowilds-pagination wow fadeInUp text-center">
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                        className={currentPage === 1 ? "disabled" : ""}
                      >
                        <i className="far fa-arrow-left" />
                      </a>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <li key={index}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(index + 1);
                          }}
                          className={currentPage === index + 1 ? "active" : ""}
                        >
                          {index + 1 < 10 ? `0${index + 1}` : index + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                        className={currentPage === totalPages ? "disabled" : ""}
                      >
                        <i className="far fa-arrow-right" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  };

  const calculateOffset = (index, galleryCount) => {
    const baseOffset = 700;
    const threshold = 0.5;
    const viewportHeight = window.innerHeight;
    const imageHeight = 300;

    const estimatedTopPosition = index * imageHeight;
    const offset = Math.max(baseOffset, estimatedTopPosition - viewportHeight * threshold);

    return offset;
  };

  return (
    <section className="gallery-area pt-100 pb-70">
      <div className="container">
        
        {renderGalleries()}
      </div>
    </section>
  );
}

export default GalleryPage;
