import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AccommodationSkelton from "../../AccommodationPage/AccommodationSkelton";
import PromotionalPopup from "../../../components/PromotionalPopup";
import HomeSlider from "../../../components/Slider/HomeSlider";


// Function to conditionally load the image URL based on the environment
function getPlacesImageUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_PLACES_IMAGE_URL_DEVELOPMENT
    : process.env.REACT_APP_PLACES_IMAGE_URL_PRODUCTION;
}

function getPlacesApiUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_PLACES_DEVELOPMENT
    : process.env.REACT_APP_API_URL_PLACES_PRODUCTION;
}

// Function to strip HTML tags and truncate content by character limit
function stripHtmlAndTruncate(content, charLimit) {
  const div = document.createElement("div");
  div.innerHTML = content;

  const plainText = div.innerText;
  return plainText.length > charLimit
    ? plainText.substring(0, charLimit) + "..."
    : plainText;
}

const StarRating = ({ rating }) => {
  const totalStars = 5;

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= totalStars; i++) {
      if (i <= Math.floor(rating)) {
        stars.push(
          <i className="fas fa-star" key={i} style={{ color: "#ffc107" }}></i>
        );
      } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
        stars.push(
          <i
            className="fas fa-star-half-alt"
            key={i}
            style={{ color: "#ffc107" }}
          ></i>
        );
      } else {
        stars.push(
          <i className="far fa-star" key={i} style={{ color: "#ddd" }}></i>
        );
      }
    }
    return stars;
  };

  return (
    <div className="star-rating" style={{ marginBottom: "10px" }}>
      {renderStars()}
      <span style={{ marginLeft: "10px", fontWeight: "bold", color: "#333" }}>
        {rating ? `(${rating}/5)` : "Not Rated"}
      </span>
    </div>
  );
};

function PlacesList() {
  const imageUrl = getPlacesImageUrl();
  const placesApiUrl = getPlacesApiUrl();

  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [category, setCategory] = useState("");
  const [sortOption, setSortOption] = useState("rating");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(placesApiUrl, {
          params: {
            page: currentPage,
            per_page: 9,
            search: searchQuery,
            category,
            sort_by: sortOption,
            sort_order: "desc",
          },
        });

        if (response.data && response.data.data) {
          setPlaces(response.data.data);
          setTotalPages(response.data.total_pages);
        } else {
          throw new Error("Unexpected API response format");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlaces();
  }, [currentPage, searchQuery, category, sortOption]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Helmet>
        <title>Top Places to Visit in Barot Valley</title>
        <meta
          name="description"
          content="Discover the best places to visit in Barot Valley, Himachal Pradesh. Explore stunning viewpoints, serene landscapes, and must-visit attractions in this hidden gem."
        />
      </Helmet>

      <PromotionalPopup />

      <section className="products-section pt-100 pb-90">
        <div className="container">
          <h3 className="text-center mb-4">
            Explore Stunning Places in Barot Valley
          </h3>

          {/* Search and Filters */}
          <div className="row g-3 mb-4">
            <div className="col-lg-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="searchInput"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <label htmlFor="searchInput">Search Places</label>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="categorySelect"
                  value={category}
                  onChange={handleCategoryChange}
                >
                  <option value="">All Categories</option>
                  <option value="nature">Top Rated</option>
                  <option value="adventure">Recommended</option>
                  <option value="cultural">Popular</option>
                  <option value="wildlife">Wildlife & Eco-Tourism</option>
                </select>
                <label htmlFor="categorySelect">Filter by Category</label>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="sortSelect"
                  value={sortOption}
                  onChange={handleSortChange}
                >
                  <option value="rating">Sort by Rating</option>
                  <option value="name">Sort by Name</option>
                </select>
                <label htmlFor="sortSelect">Sort by</label>
              </div>
            </div>
          </div>

          {/* Places List */}
          <div className="row">
            {isLoading ? (
              <AccommodationSkelton count={9} />
            ) : error ? (
              <div className="text-center">{error}</div>
            ) : places.length > 0 ? (
              places.map((place, index) => (
                <div className="col-xl-4 col-md-6 mb-4" key={index}>
                  <div className="card shadow-sm">
                    <div className="img-holder">
                      <Link to={`/places/${place.slug}`}>
                        <img
                          src={`${imageUrl}/${place.image}`}
                          alt={place.name}
                          className="card-img-top"
                        />
                      </Link>
                      <div className="badge">{place.badge_status}</div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">
                        <Link to={`/places/${place.slug}`}>{place.name}</Link>
                      </h5>
                      <p className="card-description">
                        {stripHtmlAndTruncate(place.description, 150)}
                      </p>
                      <StarRating rating={Number(place.rating)} />
                      <button
                        className="btn btn-outline-primary btn-sm mt-2"
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                              place.location
                            )}`,
                            "_blank"
                          )
                        }
                      >
                        Get Directions
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">No places available.</div>
            )}
          </div>

          {/* Pagination */}
          {!isLoading && !error && totalPages > 1 && (
            <div className="d-flex justify-content-center mt-4">
              <nav>
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default PlacesList;
