import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

import GallerySkelton from "./SketonComponents/GallerySkelton";

// Function to conditionally load the image URL based on environment
function getGalleryImageUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_GALLERY_IMAGE_URL_DEVELOPMENT
    : process.env.REACT_APP_GALLERY_IMAGE_URL_PRODUCTION;
}

// Function to conditionally load the API URL based on environment
function getGalleryapiUrl() {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_GALLERY_DEVELOPMENT
    : process.env.REACT_APP_API_URL_GALLERY_PRODUCTION;
}

const GallerySection = () => {
  const location = useLocation(); // Get the current location
  const imageUrl = getGalleryImageUrl();
  const GalleryApiUrl = getGalleryapiUrl();

  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(GalleryApiUrl);

        if (response.status === 200 && response.data && response.data.data) {
          setGalleries(response.data.data); // Assuming `data` contains the array of galleries
        } else {
          throw new Error("Unexpected API response format.");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching galleries:", error);
        setError("Failed to load gallery images. Please try again later.");
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Conditionally limit data to 4 items if the route is `/home`
  const displayedGalleries = location.pathname === "/home" ? galleries.slice(0, 4) : galleries;

  const sliderActive5Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="gallery-section mbm-150">
      <div className="container-fluid">
        {isLoading ? (
          <GallerySkelton />
        ) : error ? (
          <p style={{ color: "red", textAlign: "center" }}>{error}</p>
        ) : displayedGalleries.length > 0 ? (
          <Slider {...sliderActive5Item} className="slider-active-5-item wow fadeInUp">
            {displayedGalleries.map((gallery) => (
              <div key={gallery.id} className="single-gallery-item">
                <div className="gallery-img">
                  <img
                    src={`${imageUrl}/${gallery.image}`}
                    alt={gallery.alt_text || "Gallery Image"}
                    style={{ width: "332px", height: "300px", objectFit: "cover" }}
                    onError={(e) => (e.target.src = `${imageUrl}/placeholder-image.jpg`)} // Fallback for broken images
                  />
                  <div className="hover-overlay">
                    <a href={`${imageUrl}/${gallery.image}`} className="icon-btn img-popup">
                      <i className="far fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p style={{ textAlign: "center" }}>No gallery images available at the moment.</p>
        )}
      </div>
    </section>
  );
};

export default GallerySection;
