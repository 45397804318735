import React from 'react'

function Why() {
  return (
    <>
     {/*====== Start Why Choose Section ======*/}
     <section className="why-choose-section gray-bg pt-100 pb-50">
        <div className="container">
          <div className="row align-items-xl-center">
            <div className="col-xl-12">
              {/*=== Choose Content Box ===*/}
              <div className="choose-content-box pr-lg-70">
                {/*=== Section Title ===*/}
                <div className="section-title mb-45 wow fadeInDown">
                  
                  <h2>Why Travelers Love Choosing Barot Valley</h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-four mb-45 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-hiking" />
                      </div>
                      <div className="text">
                        <h4 className="title">Adventure opportunities</h4>
                        <p>
                        Barot Valley is a paradise for adventure seekers, offering thrilling activities like trekking through scenic trails, camping under the stars, trout fishing in crystal-clear rivers, and birdwatching in lush green landscapes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-four mb-45 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-solar-energy" />
                      </div>
                      <div className="text">
                        <h4 className="title">Pleasant weather</h4>
                        <p>
                        Known for its pleasant climate, Barot Valley welcomes visitors throughout the year. Whether it’s the cool summer breeze or the snowy winters, this destination promises a refreshing getaway any season.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-four mb-45 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-journey" />
                      </div>
                      <div className="text">
                        <h4 className="title">Offbeat destination</h4>
                        <p>
                        Unlike the crowded tourist hotspots of Himachal Pradesh, Barot Valley offers an untouched charm. This offbeat destination provides travelers with a unique experience, far from the bustling city life.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/*=== Fancy Icon Box ===*/}
                    <div className="fancy-icon-box-four mb-45 wow fadeInUp">
                      <div className="icon">
                        <i className="flaticon-caravan" />
                      </div>
                      <div className="text">
                        <h4 className="title">Peaceful atmosphere</h4>
                        <p>
                        Barot Valley’s serene surroundings, combined with the warm and friendly demeanor of the locals, create a peaceful retreat where visitors feel at home. It’s the perfect spot to relax and recharge.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      {/*====== End Why Choose Section ======*/}
    </>
  )
}

export default Why