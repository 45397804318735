import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import PageBanner from "../../components/PageBanner";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
import { FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";

function BlogDetails() {
  const { slug } = useParams();

  const blogApiSlug = process.env.REACT_APP_BlogApiUrl_blogs_using_slug;
  const BlogApiUrl = `${blogApiSlug}${slug}`;

  const [blog, setBlog] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(BlogApiUrl);

        if (response.data && response.data.length > 0) {
          const blogData = response.data[0];
          setBlog(blogData);

          // Fetch the featured media if available
          if (blogData._links?.["wp:featuredmedia"]?.[0]?.href) {
            const mediaResponse = await axios.get(blogData._links["wp:featuredmedia"][0].href);
            setFeaturedImage(mediaResponse.data.source_url); // Extract the image URL
          }
        } else {
          setError("No blog found with this slug.");
        }
      } catch (err) {
        console.error("Error fetching blog details:", err);
        setError(err.message || "An error occurred while fetching the blog.");
      } finally {
        setIsLoading(false);
      }
    };

    if (slug) fetchBlogDetails();
  }, [slug]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!blog) return <p>Blog not found.</p>;

  const formatIndianDate = (isoString) =>
    moment.tz(isoString, "Asia/Kolkata").format("YYYY-MM-DD");
  const formattedDate = blog?.date ? formatIndianDate(blog.date) : "Unknown Date";

  return (
    <>
      <Helmet>
        <title>{blog?.title?.rendered || "Blog Details"}</title>
        <meta
          name="description"
          content={
            blog?.excerpt?.rendered
              ? blog.excerpt.rendered.replace(/(<([^>]+)>)/gi, "")
              : "Read this blog to explore amazing details and insights."
          }
        />
      </Helmet>

      <PageBanner pageTitle="Blog Details" />

      <section className="shop-details-section pt-100 pb-50">
        <div className="container">
          <div className="product-details-wrapper">
            <div className="row align-items-xl-center">
              <div className="col-lg-6">
                {featuredImage ? (
                  <img
                    src={featuredImage}
                    className="rounded mb-40"
                    alt={blog?.title?.rendered || "Blog"}
                    style={{ width: "100%", borderRadius: "10px" }}
                  />
                ) : (
                  <p>No image available</p>
                )}
              </div>

              <div className="col-lg-6">
                <div className="product-info mb-50 pl-lg-70 wow fadeInRight">
                  <h4 className="title">{blog?.title?.rendered || "No Title"}</h4>
                  <p>
                    <strong>Date Published:</strong> {formattedDate}
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: blog?.excerpt?.rendered || "" }} />

                  {/* Share Icons */}
                  <div style={{ marginTop: "20px" }}>
                    <p style={{ fontWeight: "bold" }}>Share this blog:</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#4267B2",
                          color: "#fff",
                          borderRadius: "50%",
                          textDecoration: "none",
                        }}
                      >
                        <FaFacebookF />
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#1DA1F2",
                          color: "#fff",
                          borderRadius: "50%",
                          textDecoration: "none",
                        }}
                      >
                        <FaTwitter />
                      </a>
                      <a
                        href={`https://api.whatsapp.com/send?text=${window.location.href}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#25D366",
                          color: "#fff",
                          borderRadius: "50%",
                          textDecoration: "none",
                        }}
                      >
                        <FaWhatsapp />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Styled Blog Content */}
            <div className="row">
              <div className="col-xl-12">
                <div
                  className="content-box"
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    lineHeight: "1.8",
                    fontSize: "1.1rem",
                    color: "#333",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: blog?.content?.rendered || "" }}
                    className="styled-html-content"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Custom Styles for Rendered HTML */}
      <style>
        {`
          .styled-html-content h1,
          .styled-html-content h2,
          .styled-html-content h3 {
            color:rgb(121, 29, 26); /* Highlight important headings */
            font-weight: bold;
            margin-bottom: 20px;
          }

          .styled-html-content p {
            margin-bottom: 15px;
            color: #555;
          }

          .styled-html-content strong {
            color: #63ab45; /* Highlight bold/important text */
          }

          .styled-html-content ul {
            margin-left: 20px;
            margin-bottom: 15px;
          }

          .styled-html-content ul li {
            margin-bottom: 10px;
            list-style-type: disc;
          }

          .styled-html-content img {
            max-width: 100%;
            border-radius: 5px;
            margin: 20px 0;
          }

          .styled-html-content table {
            width: 100%;
            border-collapse: collapse;
            margin: 20px 0;
          }

          .styled-html-content table th,
          .styled-html-content table td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
          }

          .styled-html-content table th {
            background-color: #f4f4f4;
          }

          .styled-html-content a {
            color: #007bff;
            text-decoration: none;
          }

          .styled-html-content a:hover {
            text-decoration: underline;
          }
        `}
      </style>
    </>
  );
}

export default BlogDetails;
