import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Slider from "react-slick";

function AccommodationSkelton() {
  const sliderActive4Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="about-section pt-100">
        <div className="container-fluid">
          <Slider
            {...sliderActive4Item}
            className="slider-active-4-item wow fadeInUp"
          >
            {/* Skeleton Items */}
            <div className="single-features-item mb-40">
              <div className="img-holder">
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={415}
                  height={350}
                  sx={{
                    background: "linear-gradient(to bottom, #a9a9a9, #d4d4d4)",
                    filter: "blur(10px)"
                  }}
                />
              </div>
            </div>
            <div className="single-features-item mb-40">
              <div className="img-holder">
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={415}
                  height={350}
                  sx={{
                    background: "linear-gradient(to bottom, #a9a9a9, #d4d4d4)",
                    filter: "blur(10px)"
                  }}
                />
              </div>
            </div>
            <div className="single-features-item mb-40">
              <div className="img-holder">
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={415}
                  height={350}
                  sx={{
                    background: "linear-gradient(to bottom, #a9a9a9, #d4d4d4)",
                    filter: "blur(10px)"
                  }}
                />
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
}

export default AccommodationSkelton;
